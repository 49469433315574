import { DialogIdent, useDialogStore } from '@/stores/useDialogStore';
import type { LineItem } from '~/server/transformers/shop/lineItemTransformer';
import type { AddCampaignEvent } from '~/stores/useCart';

export default function useEditCampaignDialog() {
  const dialogStore = useDialogStore();
  interface openOptions {
    lineItem: LineItem;
  }

  interface Result {
    campaignId: string;
    items: AddCampaignEvent['items'];
    quantity: number;
  }

  return {
    open: async (data: openOptions) => {
      dialogStore.setHeadline('shop.actions.product.headline');
      return dialogStore.openDialog<Result>(DialogIdent.ACTION_PAGE, data);
    },
    getData: () => {
      return dialogStore.getDialogData<openOptions>(DialogIdent.ACTION_PAGE);
    },
    close: (
      campaignId: string,
      items: AddCampaignEvent['items'] = null,
      quantity: number,
    ) => {
      dialogStore.closeDialog({ campaignId, items, quantity });
    },
    isOpen: () => {
      return (
        dialogStore.open && dialogStore.dialogIdent === DialogIdent.ACTION_PAGE
      );
    },
  };
}
